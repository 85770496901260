import React from 'react'
import './footer.css'
import { BsLinkedin } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';
import { SiUpwork } from 'react-icons/si';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer>
      <a href="#" className='footer__logo' rel="noreferrer">Rafida Surer</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socialss">
        <a rel="noreferrer" target="_blank" href="https://www.upwork.com/freelancers/~0140bc14e74bc11ffd"><SiUpwork/></a>
        <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/rafida-kaygin/"><BsLinkedin/></a>
        <a  rel="noreferrer" target="_blank" href="https://github.com/rafidaky"><FaGithub/></a>
       
      </div>

      <div className="footer__copyright">
        <small>&copy; Rafida Surer. All rights reserved. {year}
        </small>
      </div>
    </footer>
  )
}

export default Footer