import React from "react";
import "./testimonials.css";

// import Swiper core and required modules
import { Pagination } from "swiper";
import FEED1 from '../../assets/feed1.JPEG'
import FEED2 from '../../assets/feed2.JPEG'
import FEED3 from '../../assets/feed3.JPEG'
import FEED4 from '../../assets/feed4.JPEG'
import FEED5 from '../../assets/feed5.JPEG'
import FEED6 from '../../assets/feed6.JPEG'
import FEED7 from '../../assets/feed7.JPEG'

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";



const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper
        className="container testimonials__container"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
            <SwiperSlide>
              <div className="testimonials">
              <img src={FEED4}></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonials">
              <img src={FEED6}></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonials">
              <img src={FEED1}></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonials">
              <img src={FEED2}></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonials">
              <img src={FEED5}></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{borderRadius:'10px'}} className="testimonials">
              <img src={FEED3}></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonials">
              <img src={FEED7}></img>
              </div>
            </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Testimonials;
