import React from "react";
import "./services.css";
import { BiCheck } from "react-icons/bi";

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Mobile App Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Creating Mobile App with Interactive UI/UX Design from scratch. </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Executing fully responsive mobile apps.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
              Enhanced security. 
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
              CSS, Bootstrap, Material UI Usage.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
              Realm (MongoDB Database Connection)
              </p>
            </li>
            
            <li>
              <BiCheck className="service__list-icon" />
              <p>
              Using any native components of the phones like camera, bluetooth etc.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
              App submission to Google Play, App Store and App Gallery.
              </p>
            </li>
          </ul>
        </article>
        {/* END OF Mobile App */}
        <article className="service">
          <div className="service__head">
            <h3>Bug Fix For Existing Projects </h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Refactoring your existing apps with new designs.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Adding/Removing features to existing mobile applications.
              </p>
            </li>
            
            <li>
              <BiCheck className="service__list-icon" />
              <p>Applying pixel-perfect UI and sharing source code.</p>
            </li>

            <li>  
              <BiCheck className="service__list-icon" />
              <p>
                Converting your PSD, PDF or Figma files into HTML5, CSS or React Native
                Components.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
              Implementing javascript Libraries/npm installation.
              </p>
            </li>
          </ul>
        </article>
    
      </div>
    </section>
  );
};

export default Services;
