import React from "react";
import "./about.css";
import ME from "../../assets/secondary.jpg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Me" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>4+ Years Working</small>
            </article>
            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Clients</h5>
              <small>15+ Worldwide</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>20+ Completed</small>
            </article>
          </div>

          <p>
            I've been developing mobile apps with react native for about more than 4 years professionally, I've worked in lots of different projects which you can find most of them on App Store & Google Play and App Gallery.
            <br></br>
            <br></br>
            <span></span>
            <span></span>I am able to solve complex logic or other tasks related
            to the mobile development. I have a perfect understanding of how
            JavaScript and React Native works. I have a lot of experience working with up-to-date technologies. 
            <br></br>
            <br></br>
            <span></span>
            <span></span>
            If you want to build high-quality mobile apps,
            check my portfolio below and contact me 7/24
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
