import "./contact.css";
import { MdOutlineMail } from "react-icons/md";
// import { RiMessengerLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";
import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';




const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
  e.preventDefault();
  
      emailjs.sendForm('service_axfoqat', 'template_elnj5lv', form.current, 'Mb0Gab8YcrfYKKKn4')

      e.target.reset();
  };

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineMail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>rafidakn@gmail.com</h5>
            <a href="mailto:rafidakn@gmail.com" target="_blank" rel="noreferrer">
              Send a message
            </a>
          </article>
          {/* Gelecekte eklenebilir comment'i kaldır? */}
          {/* <article className="contact__option">
            <RiMessengerLine className="contact__option-icon"/>
            <h4>Messenger</h4>
            <h5>cansurer</h5>
            <a href="https://m.me/Caniniz/" target="_blank">
              Send a message
            </a>
          </article> */}
          {/* <article className="contact__option">
            <BsWhatsapp className="contact__option-icon" />
            <h4>Whatsapp</h4>
            <h5>+905386260593</h5>
            <a
              href="https://api.whatsapp.com/send?phone=+905386260593" 
              target="_blank"
            >
              Send a message
            </a>
          </article> */}
        </div>
        {/* END OF THE CONTACT OPTİONS */}
        {/* <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Your Name" required />
          <input type="text" name="email" placeholder="Your Email" required />
          <textarea name="massage" rows="7" placeholder="Your Message" required></textarea>
          <button type="submit" className="btn btn-primary">Send Message</button>
        </form> */}
      </div>
    </section>
  );
};

export default Contact;
