
import React from 'react'
import { BsLinkedin } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';
import { SiUpwork } from 'react-icons/si';


const HeaderSocials = () => {
  return ( <div className='header__socialss'>
  
  <a rel="noreferrer" target="_blank" href="https://www.upwork.com/freelancers/~0140bc14e74bc11ffd"><SiUpwork/></a>
        <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/rafida-kaygin/"><BsLinkedin/></a>
        <a  rel="noreferrer" target="_blank" href="https://github.com/rafidaky"><FaGithub/></a>
    </div>
  )
}

export default HeaderSocials








