import React from "react";
import "./portfolio.css";
import {AiOutlineApple} from 'react-icons/ai'
import {IoLogoGooglePlaystore} from 'react-icons/io5'
import {SiHuawei} from 'react-icons/si'

import IMG1 from "../../assets/companies/pixofarm.jpeg";
import IMG2 from "../../assets/companies/helwa.png";
import IMG3 from "../../assets/companies/juno.png";
import IMG4 from "../../assets/companies/ofisimCepte.jpg";
import IMG5 from "../../assets/companies/sagligimCepte.png";
import IMG6 from "../../assets/companies/keikei.png";





const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">

      <article  className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={IMG1} alt={'fixofarm farm app'} />
              </div>
              <h3>Pixofarm - Optimize your Harvest</h3>
              <div className="portfolio__item-cta">
                <a href={'https://apps.apple.com/us/app/pixofarm/id1494932010'} rel="noreferrer" className="btn" target="_blank">
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <AiOutlineApple style={{ fontSize:'2rem' ,paddingRight:'10px' }}/>
                <p>App Store</p>
                </div>
                
                </a>
                <a href={'https://play.google.com/store/apps/details?id=com.pixofarm&hl=en&gl=US'} rel="noreferrer" className="btn" target="_blank">
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <IoLogoGooglePlaystore style={{ fontSize:'2rem' ,paddingRight:'10px' }}/>
                <p>Play Store</p>
                </div>
                
                </a>
                
              </div>
        
            </article>
            <article  className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={IMG2} alt={'helwa app'} />
              </div>
              <h3>Helwa - Online Shopping Coupons and Discounts</h3>
              <div className="portfolio__item-cta">
                <a href={'https://apps.apple.com/us/app/helwa/id1519569679'} rel="noreferrer" className="btn" target="_blank">
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <AiOutlineApple style={{ fontSize:'2rem' ,paddingRight:'10px' }}/>
                <p>App Store</p>
                </div>
                
                </a>
                <a href={'https://play.google.com/store/apps/details?id=com.app.helwa&hl=en&gl=US'} rel="noreferrer" className="btn" target="_blank">
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <IoLogoGooglePlaystore style={{ fontSize:'2rem' ,paddingRight:'10px' }}/>
                <p>Play Store</p>
                </div>
                
                </a>
                
              </div>
              <a href={'https://appgallery.huawei.com/#/app/C102602245'} rel="noreferrer" className="btn" target="_blank">
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <SiHuawei style={{ fontSize:'2rem',paddingRight:'10px' }}/>
                <p>Huawei App Gallery</p>
                </div>
                
                </a>
            </article>

            <article  className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={IMG3} alt={'Juno insurance app'} />
              </div>
              <h3>Juno - New Generation Insurance </h3>
              <div className="portfolio__item-cta">
                <a href={'https://apps.apple.com/tr/app/juno-yeni-nesil-sigortac%C4%B1l%C4%B1k/id1531863213'} rel="noreferrer" className="btn" target="_blank">
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <AiOutlineApple style={{ fontSize:'2rem' ,paddingRight:'10px' }}/>
                <p>App Store</p>
                </div>
                
                </a>
                <a href={'https://play.google.com/store/apps/details?id=com.anadolusigorta.juno&hl=en&gl=US'} rel="noreferrer" className="btn" target="_blank">
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <IoLogoGooglePlaystore style={{ fontSize:'2rem' ,paddingRight:'10px' }}/>
                <p>Play Store</p>
                </div>
                
                </a>
                
              </div>
        
            </article>

            <article  className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={IMG4} alt={'Ofisim Cepte Anadolu Sigorta App'} />
              </div>
              <h3>Ofisim Cepte - Insurance App </h3>
              <div className="portfolio__item-cta">
                <a href={'https://apps.apple.com/tr/app/ofisim-cepte/id1511072960?l=en'} rel="noreferrer" className="btn" target="_blank">
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <AiOutlineApple style={{ fontSize:'2rem' ,paddingRight:'10px' }}/>
                <p>App Store</p>
                </div>
                
                </a>
                <a href={'https://play.google.com/store/apps/details?id=com.anadolusigorta.acentemobil&hl=en&gl=US'} rel="noreferrer" className="btn" target="_blank">
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <IoLogoGooglePlaystore style={{ fontSize:'2rem' ,paddingRight:'10px' }}/>
                <p>Play Store</p>
                </div>
                
                </a>
                
              </div>
        
            </article>

            <article  className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={IMG5} alt={'Ofisim Cepte Anadolu Sigorta App'} />
              </div>
              <h3>Sagligim Cepte - Insurace App </h3>
              <div className="portfolio__item-cta">
                <a href={'https://apps.apple.com/tr/app/sa%C4%9Fl%C4%B1%C4%9F%C4%B1m-cepte/id1446919166?l=en'} rel="noreferrer" className="btn" target="_blank">
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <AiOutlineApple style={{ fontSize:'2rem' ,paddingRight:'10px' }}/>
                <p>App Store</p>
                </div>
                
                </a>
                <a href={'https://play.google.com/store/apps/details?id=com.anadolusigorta.sglcepte&hl=en&gl=US'} rel="noreferrer" className="btn" target="_blank">
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <IoLogoGooglePlaystore style={{ fontSize:'2rem' ,paddingRight:'10px' }}/>
                <p>Play Store</p>
                </div>
                
                </a>
                
              </div>
        
            </article>

            <article  className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={IMG6} alt={'Ofisim Cepte Anadolu Sigorta App'} />
              </div>
              <h3>keiKei - Shopping App </h3>
              <div className="portfolio__item-cta">
              
                <a href={'https://play.google.com/store/apps/details?id=com.keikei&hl=en&gl=US'} rel="noreferrer" className="btn" target="_blank">
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <IoLogoGooglePlaystore style={{ fontSize:'2rem' ,paddingRight:'10px' }}/>
                <p>Play Store</p>
                </div>
                
                </a>

                <a href={'https://appgallery.huawei.com/#/app/C103749711'} rel="noreferrer" className="btn" target="_blank">
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <SiHuawei style={{ fontSize:'2rem',paddingRight:'10px' }}/>
                <p>Huawei App Gallery</p>
                </div>
                
                </a>
                
              </div>
        
            </article>

            
      </div>
    </section>
  );
};

export default Portfolio;
